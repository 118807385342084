<style>




</style>

<template>
  <div class="center">
<!--    <h1 style="color: #9dc8db">     </h1>-->
    <p class="overlaytitleP">
      Hi
    </p>
    <div class="logon">
      <div :class="overlaylong">
        <div class="overlaylong-Signin" v-if="disfiex == 0">
          <h2 class="overlaylongH2">管理后台</h2>
<!--          <h3>忘记密码?</h3>-->
            <el-form ref="form" :model="loginData"  label-width="80px" >
              <el-form-item label="账号">
                <el-input v-model="loginData.account" clearable autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="密码">
                <el-input v-model="loginData.pwd" clearable show-password autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
<!--                <button class="inupbutton" type="primary" @click="onLogin">登录</button>-->
                <el-button type="primary" @click="onLogin" style="width: 220px">登录</el-button>
              </el-form-item>
            </el-form>
        </div>
        <div class="overlaylong-Signup" v-if="disfiex == 1">
          <h2 class="overlaylongH2">注册用户</h2>
          <input type="text" placeholder="用户名">
          <input type="text" placeholder="密码">
          <button class="inupbutton">注册</button>
        </div>

      </div>
      <div :class="overlaytitle">
        <div class="overlaytitle-Signin" v-if="disfiex == 0">
          <h2 class="overlaytitleH2">欢迎你</h2>

          <div class="buttongohs" @click="Signin">注册用户</div>
        </div>
        <div class="overlaytitle-Signup" v-if="disfiex == 1">
          <h2 class="overlaytitleH2">暂不对外开放</h2>
<!--          <p class="overlaytitleP">To keep connected with us please login with your personal info</p>-->
          <div class="buttongohs" @click="Signup">返回登录</div>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  data() {
    return {
      overlaylong: 'overlaylong',
      overlaytitle: 'overlaytitle',
      disfiex: 0,
      loginData: {
        account: '',
        pwd: '',
      }
    }
  },
  methods: {
    Signin() {
      this.overlaylong = "overlaylongleft"
      this.overlaytitle = "overlaytitleright"
      setTimeout(() => {
        this.disfiex = 1
      }, 200)
    },
    Signup() {
      this.overlaylong = "overlaylongright"
      this.overlaytitle = "overlaytitleleft"

      setTimeout(() => {
        this.disfiex = 0
      }, 200)

    },
    async onLogin() {
      const {data:res} = await this.$ajax.post("/api/admin/login",this.loginData);
      if (res.code === 200 && res.msg === "登录成功" && res.data.token !== ""){
        // this.$msg.success('登录成功');
        window.sessionStorage.setItem('account',this.loginData.account);
        window.sessionStorage.setItem('token',res.data.token);
        window.sessionStorage.setItem('expTime',res.data.expTime);
        window.sessionStorage.setItem('loginTime',new Date().getTime().toString());

        await this.$router.push('/');

      }else this.$msg.error('登录错误:'+res.msg);
    }
  }
}
</script>

<style scoped>
.center {
  width: 1920px;
  height: 1080px;
  /*background-image: url('https://gd-hbimg.huaban.com/9165dc3c0f8279ae5402d1f47212847bff68b49f3cb547-QEli8R');*/
  /*background-image: url(../assets/bg5.jpg);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1 {
  font-size: 30px;
  color: black;
}

.logon {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* position: relative;
  overflow: hidden; */
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  margin-top: 20px;
  display: flex;
  background: -webkit-linear-gradient(right, #4284db, #29eac4);
}

.overlaylong {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongright {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaytitle {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}


.overlaytitleH2 {
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleP {
  font-size: 15px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitleright {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-100%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitle-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaytitle-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttongohs {
  width: 180px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  margin-top: 40px;
}

.overlaylongH2 {
  font-size: 25px;
  color: black;
  /* width: 250px; */
}

.overlaylong-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaylong-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 10px 0;
  width: 240px;
}
h3{
  font-size: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.inupbutton{
  background-color: #29eac4;
  border: none;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-top: 30px;
}
</style>
