<template>
  <el-row style="margin-bottom: 10px;margin-top: -10px" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card" style="width: 80%;margin-bottom: 5px">
      <el-row>
        <el-select v-model="setForm.configLab" placeholder="请选择模板" @change="selConfigLab">
          <el-option
              v-for="item in configOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="post_setScript()" style="margin-left: 20px">保存设置</el-button>
      </el-row>

      <el-form ref="form_script" :model="setForm" label-width="80px">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="margin-top: 10px">
          <el-tab-pane label="脚本设置" name="tab_main">
            <el-card class="box-card" style="margin-bottom: 15px;">
  <!--          <el-form style="margin-top: -1px;margin-left: -10px" :rules="rules" ref="mainForm" :model="mainForm" label-width="80px" size="mini">-->

              <el-col :span="24"  style="margin-top: -10px">
                <el-form-item label="手机类型:">
                  <el-radio-group v-model="setForm.phoneType">
                    <el-radio label="雷电云"></el-radio>
                    <el-radio label="小米6"></el-radio>
                  </el-radio-group>
<!--                  <el-tag type="success" style="margin-left: 20px;"></el-tag>-->
                </el-form-item>
              </el-col>
              <el-col :span="24" style="margin-top: -20px">
                <el-form-item label="QQ 登录:">
                  <el-radio-group v-model="setForm.loginType">
                    <el-radio label="GUID"></el-radio>
                    <el-radio label="VS助手"></el-radio>
                  </el-radio-group>
                  <el-tag type="info" style="margin-left: 20px;">切换QQ账号,QQ登录类型</el-tag>
                </el-form-item>
              </el-col>
              <el-col :span="24"  style="margin-top: -20px">
                <el-form-item label="执行任务:" prop="task" style="margin-bottom: 5px;">
                  <el-select v-model="setForm.task" multiple placeholder="请选择" @change="selTask" style="width: 500px">
                    <el-option
                        v-for="item in taskList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-tag type="info" style="margin-left: 20px;">可以多选任务,脚本会按顺序执行</el-tag>
                </el-form-item>

              </el-col>
            </el-card>

            <el-card class="box-card" style="margin-bottom: 15px;margin-top: 15px" v-show="show_vs">
              <div>
                <el-col :span="10" style="margin-bottom: 5px;margin-left: 10px">
                  <el-checkbox v-model="setForm.vs_hjRest">VS从环境1执行</el-checkbox>
                </el-col>
                <el-col :span="10" style="margin-bottom: 5px">
                  <el-checkbox v-model="setForm.vs_ignoreErrorHJ">不登录异常的环境</el-checkbox>
                </el-col>

                <el-col :span="10" style="margin-bottom: 5px;margin-left: 10px">
                  <el-checkbox v-model="setForm.vs_airSwift">VS切换飞行模式</el-checkbox>
                </el-col>
                <el-col :span="10" style="margin-bottom: 5px;">
                  <el-checkbox v-model="setForm.vs_delHJ">启动时清理异常的包</el-checkbox>
                </el-col>

<!--                <el-col :span="10" style="margin-bottom: 5px;margin-left: 10px">-->
<!--                  <el-checkbox v-model="setForm.vs_realHJ">按实际环境数执行</el-checkbox>-->
<!--                </el-col>-->
                <el-col :span="10" style="margin-bottom: 5px;margin-left: 10px">
                  <el-checkbox v-model="setForm.vs_updateHJ">换号时更新VS环境包</el-checkbox>
                </el-col>
              </div>

              <el-col style="margin-bottom: 10px;">
                <el-tag type="info">不登录异常的环境: 如果环境包出现账户错误,异常,将不会使用</el-tag>
                <el-tag type="info">启动时清理异常的包: 每次启动前清理删除异常的环境包</el-tag>
<!--                <el-tag type="success">按实际环境数执行: 机器内有多少环境就跑多少个,将会忽略设置的环境数量</el-tag>-->
              </el-col>
                </el-card>
                <el-card class="box-card" style="margin-bottom: 15px">
                  <el-row type="flex">
                    <el-col :span="12">
                      <el-form-item label="任务轮次" prop="taskCount" style="margin-bottom: 3px;">
                        <el-input v-model="setForm.taskCount" clearable></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="每轮等待" prop="taskWait" style="margin-bottom: 3px;">
                        <el-input v-model="setForm.taskWait" placeholder="秒" clearable></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="网络超时" prop="netWait" style="margin-bottom: 3px;">
                        <el-input v-model="setForm.netWait" placeholder="秒" clearable></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12" >
                      <el-form-item label="启动等待" prop="QQwait" style="margin-bottom: 3px;">
                        <el-input v-model="setForm.QQwait" placeholder="秒" clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row type="flex">
<!--                    <el-col :span="12">-->
<!--                      <el-form-item label="跑多少号" prop="hjCount" style="margin-bottom: 3px">-->
<!--                        <el-input v-model="setForm.vs_hjCount" clearable></el-input>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="12" >
                      <el-form-item label="任务超时" prop="taskOut" style="margin-bottom: 3px">
                        <el-input v-model="setForm.taskOut" placeholder="分" clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row type="flex">
                  <el-form-item label="在线状态" prop="online" style="margin-bottom: 3px;">
                    <el-radio-group v-model="setForm.online" size="mini">
                      <el-col :span="8"><el-radio-button border label="在线"></el-radio-button></el-col>
                      <el-col :span="8"><el-radio-button border label="隐身"></el-radio-button></el-col>
                      <el-col :span="8"><el-radio-button border label="忙碌"></el-radio-button></el-col>
                    </el-radio-group>
                  </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="Q Q 密码" prop="QQpwd" style="margin-bottom: 3px;">
                      <el-input v-model="setForm.QQpwd" placeholder="自动登录QQ必须" clearable></el-input>
                    </el-form-item>
                  </el-row>
                  <el-form-item label="图灵账号" prop="tlUser" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.tlUser" placeholder="滑块打码必须" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="图灵密码" prop="tlPwd" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.tlPwd" placeholder="滑块打码必须" clearable></el-input>
                  </el-form-item>


                </el-card>

<!--            <el-divider content-position="left">其他设置</el-divider>-->

  <!--          <el-form ref="form_script" :model="setForm" label-width="80px">-->

  <!--          </el-form>-->
            <div>
              <el-divider content-position="left">帮助说明</el-divider>
              <el-tag type="info">任务轮次: 1轮= 1x账号数量</el-tag>
              <el-tag type="info">网络超时: 根据你的网络状况是否良好设置</el-tag>
              <el-tag type="info">任务超时: 每个任务最大的运行时间,超出后切换到下一个</el-tag>
              <el-tag type="info">QQ密码: 掉号时自动登录需要,支持设置多个密码 ----符号分割</el-tag>
              <el-tag type="info">图灵账户: 自动登录时打码,滑块需要</el-tag>
              <el-tag type="info">图灵网址: fdyscloud.com.cn 请到这里自行注册充值</el-tag>

            </div>

          </el-tab-pane>

          <el-tab-pane label="引流设置" name="tab_add">
            <el-card class="box-card" style="margin-bottom: 15px">
<!--              <el-divider content-position="left" >好友聊天</el-divider>-->
              <el-alert title="好友聊天" type="warning" :closable="false"></el-alert>
              <el-form-item label="聊天开关">
                <el-checkbox v-model="setForm.isChat_add">添加好友</el-checkbox>
                <el-checkbox v-model="setForm.isChat_lh">留痕任务</el-checkbox>
                <el-checkbox v-model="setForm.isSend">重发话术</el-checkbox>
                <el-checkbox v-model="setForm.isOld">寻找历史好友</el-checkbox>

              </el-form-item>

              <el-form-item label="聊天话术" style="margin-bottom: -10px">
                <el-input type="textarea" :rows="5" v-model="setForm.chatMsg" placeholder="一行一句话 ##号为分隔符,随机抽取。支持变量: %表情% %字符% %图片% %换行%" style="width: 80%"></el-input>
                <el-tag type="info">变量说明:%表情%=随机表情 %字符%=随机英文字符 %图片%=聊天中发送图片(支持多张,随机选取),图片目录: Download/聊天</el-tag>
              </el-form-item>

            </el-card>

            <el-card class="box-card" style="margin-bottom: 15px">
<!--            <el-divider content-position="left" >添加好友任务</el-divider>-->
              <el-alert title="添加好友任务" type="warning" :closable="false"></el-alert>
            <el-row style="margin-bottom: 10px;margin-top: 0px">
              <el-switch v-model="setForm.task_QQspace" active-text="QQ空间" ></el-switch>
              <el-switch v-model="setForm.task_xsj" active-text="小世界" ></el-switch>
              <el-switch v-model="setForm.task_QQsearch" active-text="搜索QQ"></el-switch>
            </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="数据标识" prop="lab1" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.lab1" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="单号加人" prop="addCount" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.addCount" placeholder="个" clearable></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="加人间隔" prop="addWait" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.addWait" placeholder="秒" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="拉群编号" prop="groupLab" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.groupLab" placeholder="群编号" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="问题话术" prop="wtMsg" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.wtMsg" placeholder="##号为分隔符,随机抽取" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="验证话术" prop="yzMsg" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.yzMsg" placeholder="##号为分隔符,随机抽取" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-tag type="info">单号加人: 每次任务最大加人数,到达或者备注异常切换下一个</el-tag>
                <el-tag type="info">数据标识: 在网页后台导入数据时填写的标识</el-tag>
              </el-row>
            </el-card>

            <el-card class="box-card" style="margin-bottom: 15px">
<!--              <el-divider content-position="left">留痕任务</el-divider>-->
              <el-alert title="留痕任务" type="warning" :closable="false"></el-alert>
              <el-row style="margin-bottom: 10px;margin-top: 0px">
                <el-col :span="20">
                  <el-form-item label="" prop="dz" style="margin-bottom: 3px;margin-left: -70px">
                    <el-switch v-model="setForm.dz" active-text="点赞说说" ></el-switch>
                    <el-switch v-model="setForm.xsjGZ" active-text="小世界关注" ></el-switch>
                  </el-form-item>
                </el-col>
<!--                <el-col :span="20" style="margin-top: 5px;margin-left: 15px;margin-bottom: 15px">-->
<!--                  <el-checkbox v-model="setForm.checkJX">启动时检查精选</el-checkbox>-->
<!--                </el-col>-->

                <el-col :span="12">
                  <el-form-item label="数据标识" prop="lab2" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.lab2" ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" >
                  <el-form-item label="点赞次数" prop="dzCount" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.dzCount" placeholder="对方作品" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="留痕次数" prop="lhCount" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.lhCount" placeholder="访问空间数" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="关注次数" prop="xsjCount" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.xsjCount" placeholder="小世界关注次数" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="停留时间" prop="lhWait" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.lhWait" placeholder="秒" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="关注条件" prop="xsjToGZ" style="margin-bottom: 3px">
                    <el-input v-model="setForm.xsjToGZ" placeholder="留痕多少关注"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="留痕多少飞行模式" prop="lhToAirSwift" label-width="150px" style="margin-bottom: 3px;margin-left: -15px">
                    <el-input v-model="setForm.lhToAirSwift" placeholder="留痕多少飞行模式" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="留痕多少测试点赞" prop="lhToDZ" label-width="150px" style="margin-bottom: 3px;margin-left: -15px">
                    <el-input v-model="setForm.lhToDZ" placeholder="留痕多少测试点赞"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>

            <el-card class="box-card" style="margin-bottom: 15px">
<!--              <el-divider content-position="left">结伴任务</el-divider>-->
              <el-alert title="结伴任务" type="warning" :closable="false"></el-alert>
              <el-row style="margin-bottom: 10px;margin-top: 0px">
                <el-col :span="20">
                  <el-form-item label="" prop="jb_putDT" style="margin-bottom: 3px;margin-left: -70px">
                    <el-switch v-model="setForm.jb_putDT" active-text="发动态" ></el-switch>
                    <el-switch v-model="setForm.jb_chat" active-text="即时闪聊" ></el-switch>
                  </el-form-item>
                </el-col>

                <el-col :span="20" style="margin-top: 5px;margin-left: 15px;margin-bottom: 15px">
                  <el-checkbox v-model="setForm.jb_Photo">发动态图片</el-checkbox>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="匹配间隔" prop="jb_chatWait" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.jb_chatWait" placeholder="秒" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="动态话术" prop="jb_dtMsg" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.jb_dtMsg" placeholder="##号为分隔符,随机抽取" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="结伴聊天" prop="jb_chatMsg" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.jb_chatMsg" placeholder="支持变量%表情%%随机% ##号为分隔符,随机抽取" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-tag type="info">结伴聊天话术: 支持变量%表情% %随机%=结伴内随机话术</el-tag>
              <el-tag type="info">结伴发动态图:目录Download/结伴,脚本自动上传,随机选取</el-tag>
            </el-card>

            <el-card class="box-card" style="margin-bottom: 15px">
<!--              <el-divider content-position="left">小世界任务</el-divider>-->
              <el-alert title="小世界任务" type="warning" :closable="false"></el-alert>
              <el-row style="margin-bottom: 10px;margin-top: 0px">
                <el-col :span="20">
                  <el-form-item label="" prop="dz" style="margin-bottom: 3px;margin-left: -70px">
                    <el-switch v-model="setForm.xsj_gz" active-text="小世界关注" ></el-switch>
                    <el-switch v-model="setForm.xsj_chat" active-text="小世界聊天" ></el-switch>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="数据标识" prop="lab3" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.lab3" ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" >
                  <el-form-item label="关注次数" prop="xsjCount" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.xsj_Count" placeholder="小世界关注次数" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" >
                  <el-form-item label="停留时间" prop="lhWait" style="margin-bottom: 3px;">
                    <el-input v-model="setForm.xsj_Wait" placeholder="秒" ></el-input>
                  </el-form-item>
                </el-col>

              </el-row>

            </el-card>
<!--            <el-divider content-position="left">帮助说明</el-divider>-->


          </el-tab-pane>

          <el-tab-pane label="修改资料" name="tab_editdata">
            <!--            <el-card class="box-card">-->
            <!--                <div slot="header" class="clearfix">-->
            <!--                    <span>修改资料选项</span>-->
            <!--                </div>-->
  <!--          <el-form style="margin-top: 1px;margin-left: -10px" :rules="rules" ref="editForm" :model="editForm" label-width="80px" size="mini">-->

              <el-row>

                <el-col :span="7" style="margin-bottom: 3px;margin-left: 10px">
                  <el-checkbox v-model="setForm.editNick">修改名字</el-checkbox>
                </el-col>
                <el-col :span="7" style="margin-bottom: 3px;margin-left: 0px">
                  <el-checkbox v-model="setForm.sexNv">姓别女</el-checkbox>
                </el-col>
                <el-col :span="7" style="margin-bottom: 3px;margin-left: 0px">
                  <el-checkbox v-model="setForm.editAvatar">修改头像</el-checkbox>
                </el-col>
                <el-col :span="7" style="margin-bottom: 3px;margin-left: 10px;">
                  <el-checkbox v-model="setForm.hideSpace">隐藏空间</el-checkbox>
                </el-col>
                <el-col :span="7" style="margin-bottom: 3px;margin-left: 0px">
                  <el-checkbox v-model="setForm.hideLv">隐藏等级</el-checkbox>
                </el-col>
                <el-col :span="7" style="margin-bottom: 3px;margin-left: 0px">
                  <el-checkbox v-model="setForm.jxPhoto">精选照片</el-checkbox>
                </el-col>
                <el-col :span="8" style="margin-top: 3px;margin-left: 10px">
                  <el-checkbox v-model="setForm.putRZ">发布说说/图片</el-checkbox>
                </el-col>
                <el-col :span="8" style="margin-top: 3px;margin-left: 10px">
                  <el-checkbox v-model="setForm.xsjModify">小世界修改资料</el-checkbox>
                </el-col>
                <el-col>
                  <el-form-item label="名字设置" prop="nick" style="margin-bottom: 3px;margin-top: 10px">
                    <el-input v-model="setForm.nick" placeholder="##分隔随机抽取。 支持随机名字:变量:%姓% %名%" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="说说话术">
                    <el-input v-model="setForm.kjMsg" placeholder="##号为分隔符,随机抽取。支持变量: %表情% %字符% " style="width: 80%"></el-input>
                    <el-tag type="info">变量说明:%表情%=随机表情 %字符%=随机英文字符。 图片目录: Download/说说</el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
  <!--          </el-form>-->
            <div>
              <!--                <el-tag type="info">说明帮助:</el-tag>-->
              <el-divider content-position="left">名字设置说明</el-divider>
              <el-tag type="info">##分隔随机取,支持变量:%姓% %名%</el-tag>
              <el-divider content-position="left">修改头像说明</el-divider>
              <el-tag type="info">把图片复制到手机目录Download/头像,脚本会自动选取</el-tag>
              <el-divider content-position="left">精选照片说明</el-divider>
              <el-tag type="info">把图片复制到手机目录Download/精选,脚本自动上传,最多3张图</el-tag>
              <el-divider content-position="left">说说图片说明</el-divider>
              <el-tag type="info">设置好说说话术,##号为分隔符,随机抽取,留空随机话术</el-tag>
              <el-tag type="info">把图片复制到手机目录Download/说说,脚本自动上传,有图就发,没有不发</el-tag>
            </div>

            <!--            </el-card>-->
          </el-tab-pane>

          <el-tab-pane label="图片上传" name="tab_upload">
<!--            <el-divider content-position="left">精选图片</el-divider>-->
            <el-card class="box-card" style="margin-bottom: 15px">
            <el-form-item label="精选图片" style="margin-top: 1px ;width: 50%">
              <el-upload
                  ref="upload_jx"
                  action=""
                  list-type="picture-card"
                  accept=".jpg,.png"
                  multiple
                  :auto-upload="false"
                  :file-list="fileList_jx"
                  :limit="3"
                  :http-request="addUploadJX"
                  :on-exceed="handleLimit"
                  :on-change="handChangeFile_jx"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                    v-if="!upload_disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                    v-if="!upload_disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove_jx(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
                </div>
              </el-upload>

              <el-col>
                <el-button style="margin-left: 10px;" size="small" type="warning" @click="upload_clear('jx')">清除精选图片</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadPhoto('jx')">上传精选图片</el-button>
                <el-checkbox v-model="setForm.changeJX" style="margin-left: 20px">启动时强制更换精选图片</el-checkbox>
              </el-col>
            </el-form-item>
            </el-card>
            <el-card class="box-card" style="margin-bottom: 15px">
            <el-form-item label="说说话术">
              <el-input v-model="setForm.kjMsg" placeholder="##号为分隔符,随机抽取。支持变量: %表情% %字符% " style="width: 80%"></el-input>
              <el-tag type="info">变量说明:%表情%=随机表情 %字符%=随机英文字符。 图片目录: Download/说说</el-tag>
            </el-form-item>

            <el-form-item label="说说图片" style="width: 50%">
              <el-upload
                  ref="upload_kj"
                  action=""
                  list-type="picture-card"
                  accept=".jpg,.png"
                  multiple
                  :auto-upload="false"
                  :file-list="fileList_kj"
                  :limit="3"
                  :http-request="addUploadKJ"
                  :on-exceed="handleLimit"
                  :on-change="handChangeFile_kj"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                    v-if="!upload_disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                    v-if="!upload_disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove_jx(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
                </div>
              </el-upload>

              <el-col >
                <el-button style="margin-left: 10px;" size="small" type="warning" @click="upload_clear('kj')">清除说说图片</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadPhoto('kj')">上传说说图片</el-button>
                <el-checkbox v-model="setForm.changeKJ" style="margin-left: 20px">启动时强制更换说说</el-checkbox>
              </el-col>
            </el-form-item>
            </el-card>
          </el-tab-pane>

        </el-tabs>

      </el-form>

    </el-card>

    <el-dialog :visible.sync="upload_dialogVisible">
      <img width="100%" :src="upload_dialogImageUrl" alt="">
    </el-dialog>


  </el-row>
</template>

<script>


export default {
  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      configOptions: [{
        value: '配置1',
      },{
        value: '配置2',
      },{
        value: '配置3',
      },{
        value: '配置4',
      },{
        value: '配置5',
      },],
      activeName: 'tab_main',
      taskList: [{
        value: '添加好友',
        label: '添加好友'
      }, {
        value: '留痕任务',
        label: '留痕任务'
      }, {
        value: '结伴任务',
        label: '结伴任务'
      }, {
        value: '小世界任务',
        label: '小世界任务'
      }, {
        value: '修改资料',
        label: '修改资料'
      }, {
        value: '统计好友',
        label: '统计好友'
      }, {
        value: '打卡签到',
        label: '打卡签到'
      }, {
        value: '发布说说',
        label: '发布说说'
      }, {
        value: '批量拉群',
        label: '批量拉群'
      }],

      configArr:[],
      setForm: {
        phoneType:"小米6",
        loginType:"VS助手",
        chatMsg:'',
        configLab: '配置1',
        isSend:false,
        isChat_add:false,
        isChat_lh:false,
        isOld:false,
        changeJX:false,
        changeKJ:false,
        form_jx:null,
        form_kj:null,

        //vs
        vsList:[],
        VSidx:'',
        // vs_hjCount: '40',
        vs_hjRest:false,
        vs_ignoreErrorHJ:true,
        vs_airSwift:true,
        vs_delHJ:true,
        // vs_realHJ:true,
        vs_updateHJ:true,

        //main
        QQpwd:'',
        taskCount:'1',
        online: '在线',
        netWait:'10',
        tlUser:'',
        tlPwd:'',
        QQwait:'10',
        taskWait:'60',
        taskOut:"60",
        task:[],

        //addForm
        task_QQspace: true,
        task_xsj: true,
        task_QQsearch: false,
        addWait: '5',
        addCount:'50',
        wtMsg:'老同学##朋友介绍##妹妹闺蜜',
        yzMsg:'',
        groupLab:'1',
        lhFirends:true,
        lhFirendsCount:'1',
        dz:true,
        dzCount:'50',
        lhCount:'100',
        lhWait:'5',
        lhToAirSwift:"20",
        lhToDZ:"100",
        lab1:'',
        lab2:'',
        lab3:'',
        // checkJX:true,
        xsjCount:"10",
        xsjGZ:true,
        xsjToGZ:"10",
        jb_putDT:true,
        jb_chat:false,
        jb_dtMsg:"摸鱼##好无聊##咳咳##肚子好饿##%表情%",
        jb_chatMsg:"%表情%%随机%",
        jb_Photo:true,
        jb_chatWait:"10",
        xsj_gz:true,
        xsj_chat:true,
        xsj_Wait:"5",
        xsj_Count:"200",

        //editForm
        nick:'%姓%%名%',
        hideSpace:false,
        hideLv:false,
        jxPhoto:false,
        editNick:true,
        editAvatar:true,
        sexNv:true,
        putRZ:false,
        kjMsg:'瞄一下我的精选图,找我玩耍丫',
        xsjModify:false

      },
      fileList_jx: [],
      fileList_kj: [],
      rules: {
        name: [
          { type: 'Number',required: true, message: '请输入生成数量', trigger: 'blur'},
          {min: 1, max: 3, message: '长度在 1 到 3 个字符', trigger: 'blur'}
        ],
      },

      machineLog:'',
      timeoutId:null,
      upload_dialogImageUrl: '',
      upload_dialogVisible: false,
      upload_disabled: false,
      fullscreenLoading:true,
      show_guid:false,
      show_vs:false,

    }
  },
  watch: {
    //QQ登录类型
    'setForm.loginType'(newValue, oldValue) {
      if (newValue === "GUID"){
        this.show_vs = false;
        this.show_guid = true;
      }else if (newValue === "VS助手"){
        this.show_vs = true;
        this.show_guid = false;
      }
    },
  },
  created() {
    this.loadDefault();
    this._showVS();
  },
  beforeDestroy() {

  },
  methods:{
    _showVS(){
      if (this.setForm.loginType === "GUID"){
        this.show_vs = false;
        this.show_guid = true;
      }else if (this.setForm.loginType === "VS助手"){
        this.show_vs = true;
        this.show_guid = false;
      }
    },
    handleClick(tab, event) {
      console.log(tab.name, event);
    },
    selTask(value){
      console.log(value);
    },
    loadDefault(){
      this.getDefaultConfig();
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.selConfigLab('配置1');
        this.fullscreenLoading = false;
      }, 1000);
    },
    uploadPhoto(type) {
      this.$msgBox.confirm('确认上传？现有的图片将被替换','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            let formData;
            if (type === "jx"){
              this.setForm.form_jx = new FormData();
              this.setForm.form_jx.append('configLab', this.setForm.configLab);
              this.setForm.form_jx.append('type', 'jx');
              this.setForm.form_jx.append('account', this.userName);
              this.$refs.upload_jx.submit();
              console.log("form_jx",this.setForm.form_jx);
              formData = this.setForm.form_jx;

            }else if (type === "kj"){
              this.setForm.form_kj = new FormData();
              this.setForm.form_kj.append('configLab', this.setForm.configLab);
              this.setForm.form_kj.append('type', 'kj');
              this.setForm.form_kj.append('account', this.userName);
              this.$refs.upload_kj.submit();
              console.log("form_kj",this.setForm.form_kj);
              formData = this.setForm.form_kj;
            }

            const {data: res} = await this.$ajax.post("/api/config/upload", formData);
            if (res.code === 200) {
              this.$msgNot.success(res.msg);
              await this.getDefaultConfig();
              clearTimeout(this.timeoutId);
              this.timeoutId = setTimeout(() => {
                this.selConfigLab(this.setForm.configLab);
              }, 500);
            } else {
              this.$msgNot.error(res.msg);
            }

          }).catch(()=>{

      });

    },

    upload_clear(type){
      this.$msgBox.confirm('确认清除云端图片？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then( ()=>{
                if (type === "jx"){
                  this.fileList_jx = [];
                }else if (type === "kj"){
                  this.fileList_kj = [];
                }

          }).catch(()=>{

          });
    },
    //添加精选图片
    addUploadJX({ file }) {
      console.log("添加精选图片",file);
      this.setForm.form_jx.append('file[]', file);

    },
    //添加空间图片
    addUploadKJ({ file }) {
      console.log("添加空间图片",file);
      this.setForm.form_kj.append('file[]', file);

    },
    uploadSuccess(res, file, fileList){
      console.log("上传成功:"+file);
    },
    beforeUpload(file) {

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$msgNot.error('上传文件大小不能超过 2MB!');
      }
      return isLt2M;
    },
    handleLimit(file, fileList) {
      this.$msgNot.error('超出限制,最多3张');
    },
    handChangeFile_jx(file, fileList) {
      this.fileList_jx = fileList;
      console.log("插入精选图",this.jx);
    },
    handChangeFile_kj(file, fileList) {
      this.fileList_kj = fileList;
      console.log("插入空间图",this.kj);
    },

    handleRemove_jx(file, fileList) {
      // console.log(JSON.stringify(file));
      //
      // this.fileList_jx.forEach((item, i) => {
      //   if (item.uid === file.uid) {
      //     this.fileList_jx.splice(i, 1)
      //   }
      // })

    },
    handleRemove_kj(file, fileList) {
      // console.log(JSON.stringify(file));
      //
      // this.fileList_kj.forEach((item, i) => {
      //   if (item.uid === file.uid) {
      //     this.fileList_kj.splice(i, 1)
      //   }
      // })

    },
    handlePreview(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.upload_dialogImageUrl = file.url;
      this.upload_dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },

    //读取配置
    selConfigLab(value) {
      console.log(value);
      if (this.configArr){
        let json = JSON.parse(this.configArr);
        for (const k in json) {
          if(value === json[k].lab){
            console.log("读取"+json[k].lab,json[k].config);
            this.setForm   = JSON.parse(json[k].config);
            this.fileList_jx  = this.setForm.jx;
            this.fileList_kj  = this.setForm.kj;
            break
          }
        }
      }
    },

    async post_setScript() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      console.log(JSON.stringify(this.setForm));
      if (this.setForm.configLab === ""){
        this.$msgNot.error('请选择模板');
        return;
      }

      this.$refs['form_script'].validate(async (valid) => {
        if (valid) {
          // const obj = Object.assign(this.setForm, this.mainForm, this.addForm,this.editForm);
          const {data: res} = await this.$ajax.post("/api/config/setConfig", {'account': this.userName, 'data':this.setForm});
          if (res.code === 200) {
            this.$msgNot.success(res.msg);
            this.showSetScript = false;
            await this.getDefaultConfig();
          } else {
            this.$msgNot.error(res.msg);
          }

        } else {
          console.log('error postForm!!');
          return false;
        }
      });

    },
    async getDefaultConfig() {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data: res} = await this.$ajax.post("/api/config/getConfig", {'account': this.userName});
      if (res.code === 200) {
        // this.$msgNot.success(res.msg);
        this.configArr = res.data;

      } else {
        this.$msgNot.error(res.msg);
      }
    }

  },
}
</script>

<style scoped>
.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-tag{
  margin-left: 5px;
}
.el-switch{
  margin-left: 10px;
}

</style>
